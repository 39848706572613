import React from 'react';
import { PostList, Layout } from '../components';
import { graphql } from 'gatsby';
import { spinalCase } from '../utils';
import config from '../../config';
import styled from 'styled-components';
import { color } from '../theme';

export const query = graphql`
  query ServicesQuery {
    postServicesList: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//posts/" }
        frontmatter: { service: { ne: null }, active: { eq: true } }
      }
      sort: { fields: [frontmatter___featuredOrder], order: ASC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 90)
          id
          fields {
            slug
          }
          frontmatter {
            title
            service
            category
            cover {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ListContainer = styled.div`
  background: ${color.light};

  & h1 {
    color: ${color.secondary};
  }
`;

const HrSeparator = styled.hr`
  margin: 0 3rem;
  background: ${color.grey}66;
`;

export default class Services extends React.Component {
  render() {
    const {
      data: {
        postServicesList: { edges: list }
      }
    } = this.props;

    const postsGroupedByService = list.reduce((groups, item) => {
      const val = item.node.frontmatter.service;
      groups[val] = groups[val] || [];
      if (groups[val].length < 3) {
        groups[val].push(item);
      }
      return groups;
    }, {});

    return (
      <Layout>
        {config.services.order.map((service, index) => {
          return (
            <ListContainer key={service}>
              {index > 0 && <HrSeparator />}
              <PostList
                list={postsGroupedByService[service]}
                title={service}
                buttonUrl={`clase/${spinalCase(service)}/`}
                buttonText={`Más servicios de ${service}`}
              />
            </ListContainer>
          );
        })}
      </Layout>
    );
  }
}
